import { usePPA } from "context/ppa-context";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";


export default function InitialisingError(props: any) {
    const { t } = useL10n<ILocaleStrings>();
    const { initialisingError } = usePPA();

    return (
        <ScreenPlaceholder
            imageUrl="images/ErrorImage.png"
            title={t("initialisingErrorTitle")}
            message={initialisingError} />
    );
}