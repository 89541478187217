import axios, { AxiosInstance, AxiosError } from "axios";
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import { AccessToken } from '@azure/identity';
import { GetTokenOptions } from '@azure/identity';

//
export interface ILocale {
    localeCode: string;
    generated: string;
    strings: { [key: string]: string }; // This will be T when used from the useL10n hook.
}

//
export interface IL10nParameters {
    [key: string]: string | number | undefined;
}

//
interface IL10nContextForProvider {
    t: <T>(key: Extract<keyof T, string>, parameters?: IL10nParameters) => string;
    loaded: boolean;
    reloadKey: number;
    localeCode: string;
}

//
export interface IL10nContext<T> {
    t: (key: Extract<keyof T, string>, parameters?: IL10nParameters) => string;
    loaded: boolean;
    reloadKey: number;
    localeCode: string;
}

// 
export interface IL10nProviderProps<TI> {
    containerInitialised: boolean;
    apiUrl?: string;
    getAccessToken?: (scopes: string | string[], options?: GetTokenOptions) => Promise<AccessToken | null>;
    subscriberKey?: string;
    loaded?: (locale: ILocale | null) => void;
    initialStrings?: TI;
}

//
const anyTagRegExString = "{[a-zA-Z0-9]*}";
//
const tagBracesRegExString = "[{}]";
//
const anyTagRegEx = new RegExp(anyTagRegExString);
//
const allTagsRegEx = new RegExp(anyTagRegExString, "g");
//
const tagBracesReg = new RegExp(tagBracesRegExString, "g");

//
const L10nContext = createContext<Partial<IL10nContextForProvider>>({});

//
export function useL10n<T>(): IL10nContext<T> {
    const ctx = useContext(L10nContext);

    if (ctx === undefined) {
        // This should only really be a developer error if this hook is not used inside the provider.
        throw new Error("useL10n can only be used in a L10nProvider tree");
    }

    return ctx as IL10nContext<T>;
}

//
export default function L10nProvider<TI>(props: PropsWithChildren<IL10nProviderProps<TI>>) {

    const [locale, setLocale] = useState<ILocale | null>(null);
    const [reloadKey, setReloadKey] = useState<number>(0);

    //
    useEffect(() => {
        setReloadKey(Date.now());
    }, [props.initialStrings]);

    //
    const setLoaded = (locale: ILocale | null) => {
        setLocale(locale);
        if (props.loaded)
            props.loaded(locale);
    }

    //
    const getApiClient = async (getAccessToken?: (scopes: string | string[], options?: GetTokenOptions) => Promise<AccessToken | null>, subscriberKey?: string): Promise<AxiosInstance> => {

        //
        const instance = axios.create({});

        //
        if (getAccessToken) {
            try {
                const accessToken = await getAccessToken("");
                instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken?.token}`;
            }
            catch (error: unknown) {
                console.log(`L10nProvider -> getApiClient ->`, error);
            }
        }

        //
        if (subscriberKey) {
            instance.defaults.headers['ppl-api-key'] = subscriberKey;
        }

        return instance;
    };

    //
    const getLocale = async (): Promise<ILocale | null> => {
        try {
            if (props.apiUrl) {
                const response = await (await getApiClient(props.getAccessToken, props.subscriberKey)).get<ILocale>(props.apiUrl);
                setReloadKey(Date.now());
                setLoaded(response.data);
                return response.data;
            }
            return null;
        }
        catch (ex: unknown | AxiosError<string>) {
            console.log(`L10nProvider -> getLocale -> error ->`, ex);
            setLoaded(null);
            return null;
        }
    }

    //
    useEffect(() => {
        if (props.containerInitialised && props.apiUrl && (props.getAccessToken || props.subscriberKey)) {
            getLocale();
        }
    }, [props.containerInitialised, props.subscriberKey, props.apiUrl]);

    //
    function translate<T>(key: Extract<keyof T, string>, parameters?: IL10nParameters): string {
        //
        var stringValue: string = "";
        //
        if (props.initialStrings) {
            stringValue = (props.initialStrings as any)[key.toString()];
        }
        //
        if (locale?.strings) {
            const value = (locale.strings as any)[key.toString()];
            if (value) {
                stringValue = value;
            }
        }
        //
        if (parameters && stringValue) {
            // Check if the string value has any tags in it.
            if (anyTagRegEx.test(stringValue)) {
                // Do a global match to get all the tags as an array.
                const allTags = stringValue.match(allTagsRegEx);

                // Loop through all the tags and transform them if found in the properties.
                allTags?.forEach((tag: string, idx: number) => {
                    // console.log(`tag ->`, tag);
                    // Strip the tag braces to get the property name.
                    var propertyName = tag.replace(tagBracesReg, "");

                    // Initialise the value to the tag so it remains unchanged if not matched.
                    var tagValue = tag;

                    // Check if the property name exists on the properties object provided.
                    if (parameters.hasOwnProperty(propertyName)) {

                        // Get the value. 
                        tagValue = parameters[propertyName]?.toString() || "";
                    }

                    // Replace the tag in the original string value with the value of the property.
                    stringValue = stringValue.replace(tag, tagValue);
                });
            }
        }

        return stringValue || key;
    }

    const providerProps: IL10nContextForProvider = {
        t: translate,
        loaded: locale !== (null || undefined),
        reloadKey: reloadKey,
        localeCode: locale?.localeCode || ""
    };

    return (
        <L10nContext.Provider value={providerProps}>{props.children}</L10nContext.Provider>
    )
}