import { IInitialLocales } from "./locale.interfaces";


//
const preL10nLoadStrings: IInitialLocales = {
    cy: {
        initialising: "Cychwyn",
        initialisingErrorTitle: "Gwall wrth gychwyn"
    },
    de: {
        initialising: "Initialisierung",
        initialisingErrorTitle: "Fehler beim Initialisieren"
    },
    en: {
        initialising: "Initialising",
        initialisingErrorTitle: "Error initialising"
    },
    'en-us': {
        initialising: "Initializing",
        initialisingErrorTitle: "Error initializing"
    },
    es: {
        initialising: "Inicializando",
        initialisingErrorTitle: "Error al inicializar"
    },
    fr: {
        initialising: "Initialisation",
        initialisingErrorTitle: "Erreur d'initialisation"
    },
    nl: {
        initialising: "Initialiseren",
        initialisingErrorTitle: "Fout bij initialiseren"
    },
    te: {
        initialising: "Initialising.test",
        initialisingErrorTitle: "Error initialising.test"
    }
}

export default preL10nLoadStrings;