// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "context/teams-context";
import Error404 from "./errors/error-404";
import Index from "components/pages/index";
import ReportsWrapper from "./pages/reports/reports-wrapper";
import ReportsIndex from "./pages/reports/reports-index";
import ActionsWrapper from "./pages/actions/actions-wrapper";
import ActionsIndex from "./pages/actions/actions-index";
import UsersActionsIndex from "./pages/actions/users/users-actions-index";
import SharingLinksActionsIndex from "./pages/actions/sharing-links/sharing-links-actions-index";
import GuestUsersReportsIndex from "./pages/reports/guest-users/guest-users-reports-index";
import SharingLinksReportsIndex from "./pages/reports/sharing-links/sharing-links-reports-index";
import GuestUsersInWorkspaceReport from "./pages/reports/guest-users/guest-users-in-workspace-report";
import GuestUsersInTenantReport from "./pages/reports/guest-users/guest-users-in-tenant-report";
import GuestUserReport from "./pages/reports/guest-users/guest-user-report";
import PPAProvider from "context/ppa-context";
import SharingLinksInTenantReport from "./pages/reports/sharing-links/sharing-links-in-tenant-report";
import SharingLinksInWorkspaceReport from "./pages/reports/sharing-links/sharing-links-in-workspace-report";
import SignupIndex from "./pages/signup/signup-index";
import SignupWrapper from "./pages/signup/signup-wrapper";
import { useEffect, useMemo, useState } from "react";
import Initialising from "components/initialising-ppa/initialising";
import StorageReportsIndex from "./pages/reports/storage/storage-reports-index";
import StorageInWorkspaceReport from "./pages/reports/storage/storage-in-workspace-report";
import StorageInTenantReport from "./pages/reports/storage/storage-in-tenant-report";
import OrphanedObjectsReportsIndex from "./pages/reports/orphaned-objects/orphaned-objects-reports-index";
import OrphanedUsersInTenantReport from "./pages/reports/orphaned-objects/orphaned-users-in-tenant-report";
import ReportRequestsWrapper from "./pages/report-requests/report-requests-wrapper";
import ReportRequestsIndex from "./pages/report-requests/report-requests-index";
import ActionRequestsWrapper from "./pages/action-requests/action-requests-wrapper";
import ActionRequestsIndex from "./pages/action-requests/action-requests-index";
import OrphanedMailboxesInTenantReport from "./pages/reports/orphaned-objects/orphaned-mailboxes-in-tenant-report";
import RemoveOrphanedUsersAction from "./pages/action-requests/orphaned-objects/remove-orphaned-users-action";
import L10nProvider, { ILocale } from "context/l10n-context";
import { IInitialLocaleStrings, ILocaleStrings } from "loc/locale.interfaces";
import { AccessToken } from '@azure/identity';
import { GetTokenOptions } from '@azure/identity';
import preL10nLoadStrings from "loc/pre-l10n-load-translations";
import { IPPAAppProps } from "./ppa-app.props";


/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function PPAApp(props: IPPAAppProps) {

  //
  const { loading, theme, themeString, teamsUserCredential, context } = useTeamsUserCredential({
    initiateLoginEndpoint: props.config.initiateLoginEndpoint!,
    clientId: props.config.clientId!,
  });

  //
  const [initialisingPPA, setInitialisingPPA] = useState<boolean>(true);
  const [isL10nLoaded, setIsL10nLoaded] = useState<boolean>(false);

  //
  const [localeApiUrl, setLocaleApiUrl] = useState<string>();
  const [l10nContainerInitialised, setL10nContainerInitialised] = useState<boolean>(false);
  const [initialStrings, setInitialStrings] = useState<IInitialLocaleStrings>(preL10nLoadStrings.en);

  //
  const setPPAInitialised = (initialising: boolean): void => {
    setInitialisingPPA(initialising);
  }

  //
  const setL10nLoaded = (locale: ILocale | null): void => {
    setIsL10nLoaded(true);
  }

  //
  const getL10nAccessToken = async (scopes: string | string[], options?: GetTokenOptions): Promise<AccessToken | null> => {
    if (teamsUserCredential) {
      return await teamsUserCredential.getToken(scopes, options);
    }
    return null;
  }

  //
  useEffect(() => {
    if (!loading && context?.app?.locale) {
      const localeCode: string = context.app.locale;
      //const localeCode: string = 'te-st';
      //const localeCode: string = 'en-GB';
      setLocaleApiUrl(`${props.config.apiUrl}/api/l10n/${localeCode}`);
      const languageCode = localeCode.split(`-`)[0];
      if (preL10nLoadStrings[languageCode]) {
        setInitialStrings(preL10nLoadStrings[languageCode]);
      }
    }
  }, [loading, context?.app?.locale]);

  //
  useEffect(() => {
    if (localeApiUrl && initialStrings) {
      setL10nContainerInitialised(true);
    }
  }, [localeApiUrl, initialStrings]);

  //
  const initialising = useMemo<boolean>(() => {
    return initialisingPPA || !isL10nLoaded;
  }, [initialisingPPA, isL10nLoaded]);

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <L10nProvider
        containerInitialised={l10nContainerInitialised}
        getAccessToken={getL10nAccessToken}
        apiUrl={localeApiUrl}
        loaded={setL10nLoaded}
        initialStrings={initialStrings} >
        <PPAProvider
          teamsUserCredential={teamsUserCredential}
          setInitialising={setPPAInitialised}
          config={props.config}>
          <FluentProvider
            theme={
              themeString === "dark"
                ? teamsDarkTheme
                : themeString === "contrast"
                  ? teamsHighContrastTheme
                  : {
                    ...teamsLightTheme,
                    colorNeutralBackground3: "#eeeeee",
                  }
            }
            style={{ height: '100%', background: 'transparent' }}>
            <Router>
              {initialising &&
                <Initialising />}
              {!initialising &&
                <Routes>
                  <Route path="/tab" element={<Navigate to={"/"} />}></Route>
                  <Route path="/" element={<Index />}></Route>
                  <Route path="/signup" element={<SignupWrapper />}>
                    <Route index element={<SignupIndex />} />
                  </Route>
                  <Route path="reports" element={<ReportsWrapper />}>
                    <Route index element={<ReportsIndex />} />
                    <Route path="guest-users">
                      <Route index element={<GuestUsersReportsIndex />} />
                      <Route path="guest-users-tenant/:id?" element={<GuestUsersInTenantReport />} />
                      <Route path="guest-users-workspace/:id?" element={<GuestUsersInWorkspaceReport />} />
                      <Route path="guest-users-user/:id?" element={<GuestUserReport />} />
                    </Route>
                    <Route path="sharing-links">
                      <Route index element={<SharingLinksReportsIndex />} />
                      <Route path="sharing-links-tenant/:id?" element={<SharingLinksInTenantReport />} />
                      <Route path="sharing-links-workspace/:id?" element={<SharingLinksInWorkspaceReport />} />
                    </Route>
                    <Route path="storage">
                      <Route index element={<StorageReportsIndex />} />
                      <Route path="storage-tenant/:id?" element={<StorageInTenantReport />} />
                      <Route path="storage-workspace/:id?" element={<StorageInWorkspaceReport />} />
                    </Route>
                    <Route path="orphaned-objects">
                      <Route index element={<OrphanedObjectsReportsIndex />} />
                      <Route path="orphaned-users-tenant/:id?" element={<OrphanedUsersInTenantReport />} />
                      <Route path="orphaned-mailboxes-tenant/:id?" element={<OrphanedMailboxesInTenantReport />} />
                    </Route>
                  </Route>
                  <Route path="/report-requests" element={<ReportRequestsWrapper />}>
                    <Route index element={<ReportRequestsIndex />} />
                  </Route>
                  <Route path="actions" element={<ActionsWrapper />}>
                    <Route index element={<ActionsIndex />} />
                    <Route path="users" element={<UsersActionsIndex />} />
                    <Route path="sharing-links" element={<SharingLinksActionsIndex />} />
                  </Route>
                  <Route path="/action-requests" element={<ActionRequestsWrapper />}>
                    <Route index element={<ActionRequestsIndex />} />
                    <Route path="remove-orphaned-users/:id" element={<RemoveOrphanedUsersAction />} />
                  </Route>
                  <Route path="*" element={<Error404 />}></Route>
                </Routes>}
            </Router>
          </FluentProvider>
        </PPAProvider>
      </L10nProvider>
    </TeamsFxContext.Provider>
  );
}