import {
    makeStyles,
    shorthands,
    tokens,
    Button,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem
} from "@fluentui/react-components";
import {
    NavigationRegular,
    DocumentSearchRegular,
    AppsListDetailRegular,
    TaskListLtrRegular,
} from "@fluentui/react-icons";
import { IPagelayoutProps } from "./page-layout.props";
import { PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IMenuItem } from "@interfaces/common.interfaces";
import { getTopLevelMenuItems } from "lib/utils";
import { usePPA } from "context/ppa-context";
import Disabled from "components/initialising-ppa/disabled";
import LicenceExpired from "components/initialising-ppa/licence-expired";
import Breadcrumb from "components/navigation/breadcrumb/breadcrumb";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import InitialisingError from "components/initialising-ppa/initialising-error";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content 1fr',
        height: '100vh',
    },
    header: {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: tokens.colorNeutralStrokeSubtle,
        ...shorthands.padding("0em", "1em"),
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr min-content'
    },
    headerDivider: {
        width: "1px",
        height: "75%",
        backgroundColor: tokens.colorNeutralStrokeSubtle,
        ...shorthands.margin(0, "0.5em"),
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
    },
    messageBar: {
        ...shorthands.margin("1em", "2em")
    },
    content: {
        ...shorthands.overflow('auto'),
        position: 'relative'
    }
});

export default function PageLayout(props: PropsWithChildren<IPagelayoutProps>) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();
    const { hasAccess, isSignupComplete, ssoError, initialisingError, disabled, licenceExpired } = usePPA();
    const navigate = useNavigate();

    return (
        <main className={styles.mainWrapper}>
            <div>
                {hasAccess && isSignupComplete &&
                    <div className={styles.header}>
                        <div className={styles.menu}>
                            <Menu>
                                <MenuTrigger>
                                    <Button
                                        appearance="transparent"
                                        icon={<NavigationRegular />} />
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem
                                            icon={<DocumentSearchRegular />}
                                            onClick={() => navigate('/reports')}>{t("ppaReportsTitle")}</MenuItem>
                                        <MenuItem
                                            icon={<AppsListDetailRegular />}
                                            onClick={() => navigate('/report-requests')}>{t("ppaReportRequestsTitle")}</MenuItem>
                                        <MenuItem
                                            icon={<TaskListLtrRegular />}
                                            onClick={() => navigate('/action-requests')}>{t("ppaActionRequestsTitle")}</MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            <div className={styles.headerDivider}></div>
                        </div>
                        <Breadcrumb />
                        <div>
                            {/* <Button appearance="transparent" icon={<QuestionRegular />} /> */}
                        </div>
                    </div>
                }
                {ssoError &&
                    <div className={styles.messageBar}>
                        <MessageBar intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>{ssoError}</MessageBarTitle>
                            </MessageBarBody>
                        </MessageBar>
                    </div>}
            </div>
            <div className={styles.content}>
                {initialisingError && <InitialisingError />}
                {!initialisingError && disabled && <Disabled />}
                {!initialisingError && !disabled && licenceExpired && <LicenceExpired />}
                {hasAccess && props.children}
            </div>
        </main>
    );
}